import React from 'react';
import { Box, Image, Flex } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';
import Section from '../components/Section';
import Triangle from '../components/Triangle';
import markdownRenderer from '../components/MarkdownRenderer';

const Background = () => (
  <div>
    <Triangle
      color="secondaryLight"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
      invertY
    />

    <Triangle
      color="primaryDark"
      height={['20vh', '40vh']}
      width={['75vw', '70vw']}
      invertX
    />

    <Triangle
      color="backgroundDark"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
    />
  </div>
);

const ProfilePicture = styled(Image)`
  border-radius: 50%;
  transition: all 0.25s ease-out;

  &:hover {
    border-radius: 20%;
  }
`;

const About = (props) => {
  const data = useStaticQuery(graphql`
      query AboutMeQuery {
        ES: allContentfulAbout(filter: {node_locale: {eq: "es-CL"}}) {
          nodes {
            aboutMe {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            description
            profile {
              title
              image: resize(width: 450, height: 450, quality: 100) {
                src
              }
            }
          }
        }
        EN: allContentfulAbout(filter: {node_locale: {eq: "en-US"}}) {
          nodes {
            aboutMe {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            description
            profile {
              title
              image: resize(width: 450, height: 450, quality: 100) {
                src
              }
            }
          }
        }
      }
    `)
  const { aboutMe, profile } = data[props.local].nodes[0];
  return (
  <Section.Container id="about" Background={Background}>
    <Section.Header name="About me" icon="🙋‍♂️" label="person" />
    <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
      <Box width={[1, 1, 4 / 6]} px={[1, 2, 4]}>
        <Fade bottom>
          <ReactMarkdown
            source={aboutMe.childMarkdownRemark.rawMarkdownBody}
            renderers={markdownRenderer}
          />
        </Fade>
      </Box>

      <Box
        width={[1, 1, 2 / 6]}
        style={{ maxWidth: '300px', margin: 'auto' }}
      >
        <Fade right>
          <ProfilePicture
            src={profile.image.src}
            alt={profile.title}
            mt={[4, 4, 0]}
            ml={[0, 0, 1]}
          />
        </Fade>
      </Box>
    </Flex>
  </Section.Container>
)};

export default About;
