import React, { Fragment, Component } from 'react';
import Headroom from 'react-headroom';
import { Flex, Image, Box } from 'rebass';
import styled from 'styled-components';
import { SectionLinks } from 'react-scroll-section';
import Fade from 'react-reveal/Fade';
import RouteLink from './RouteLink';
import LinkAnimated from './LinkAnimated';
import Logo from './Logo/logo.png';

const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

const HeaderContainer = styled(Headroom)`
  .headroom--pinned {
    background: ${props => props.theme.colors.primaryDark};
  }

  position: absolute;
  width: 100%;
`;

const formatLinks = allLinks =>
  Object.entries(allLinks).reduce(
    (acc, [key, value]) => {
      const isHome = key === 'home';
      return isHome
        ? {
            ...acc,
            home: value,
          }
        : {
            ...acc,
            links: [...acc.links, { name: capitalize(key), value }],
          };
    },
    { links: [], home: null },
  );

class Header extends Component {
  constructor(props) {
    super(props)
  }
  getInverseLocal = (local) => {
    if(local === 'ES'){
      return 'EN'
    }
    return 'ES'
  }
  render() {
    return (
      <HeaderContainer>
        <Fade top>
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SectionLinks>
              {({allLinks}) => {
                const {home, links} = formatLinks(allLinks);

                const homeLink = home && (
                  <Image
                    src={Logo}
                    width="50px"
                    alt="Portfolio Logo"
                    onClick={home.onClick}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                );
                const navLinks = links.map(({name, value}) => (
                  <RouteLink
                    key={name}
                    onClick={value.onClick}
                    selected={value.selected}
                    name={name}
                  />
                ));

                return (
                  <Fragment>
                    {homeLink}
                    <Flex mr={[0, 3, 5]}>
                      {navLinks}
                      <a onClick={this.props.changeLocale}>
                        <Box ml={[2, 3]} color="background" fontSize={[2, 3]}>
                          <LinkAnimated>{this.getInverseLocal(this.props.local)}</LinkAnimated>
                        </Box>
                      </a>
                    </Flex>
                  </Fragment>
                );
              }}
            </SectionLinks>
          </Flex>
        </Fade>
      </HeaderContainer>
    );
  }
}

export default Header;
